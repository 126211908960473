














































import { Vue, Component, Prop } from "vue-property-decorator";
import { Observer } from "mobx-vue";

@Observer
@Component
export default class Ticket extends Vue {
  @Prop({ type: Boolean }) premium!: boolean;
  @Prop({ type: Boolean }) inactive!: boolean;
}
