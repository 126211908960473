var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"position-relative transparent overflow-hidden",attrs:{"width":"100%"}},[_c('v-img',{attrs:{"src":require(("@/assets/ticket/" + (_vm.inactive ? 'inactive' : _vm.premium ? 'premium' : 'normal') + "-ticket.svg")),"max-width":"100%"}}),_c('v-img',{staticStyle:{"position":"absolute","top":"10%","left":"7%"},attrs:{"src":require(("@/assets/ticket/icon" + (_vm.inactive ? '-inactive' : '') + ".png")),"max-width":"25%"}}),_c('v-sheet',{staticClass:"transparent d-flex flex-column pt-3",staticStyle:{"position":"absolute","top":"5%","left":"38%"},attrs:{"width":"43%","height":"90%"}},[_c('v-sheet',{staticClass:"transparent font-weight-bold orange3--text text-uppercase py-1",class:{
        'text-caption': _vm.$vuetify.breakpoint.xs,
        'text-h5': _vm.$vuetify.breakpoint.smAndUp,
      },attrs:{"height":"fit-content"}},[_vm._t("title")],2),_c('v-sheet',{staticClass:"transparent",class:{
        'font-size-8': _vm.$vuetify.breakpoint.xs,
        'text-caption': _vm.$vuetify.breakpoint.sm,
        'text-caption': _vm.$vuetify.breakpoint.mdAndUp,
      },attrs:{"height":"100%"}},[_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }